<template>
  <section class="login-content">
    <div class="container h-100">
      <div class="row align-items-center justify-content-center h-100">
        <div class="col-md-5">
          <div class="card">
            <div class="card-body">
              <div class="auth-logo">
                <img :src="logo_kerinci" class="img-fluid rounded-normal" style="height: 100px;" alt="logo">
              </div>
              <h2 class="mb-2 text-center">Login</h2>
              <form @submit.prevent="onSubmit()">
                <div class="row">
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Email</label>
                      <input class="form-control" type="text" name="email" v-model="form.email">
                    </div>
                  </div>
                  <div class="col-lg-12">
                    <div class="form-group">
                      <label>Password</label>
                      <input class="form-control" type="password" name="password" v-model="form.password">
                    </div>
                  </div>
                </div>
                
                <div class="d-flex justify-content-between align-items-center">
                  <span><router-link :to="{ name: 'Verifikasi'}" class="text-primary">Cek Surat</router-link></span>
                  <button type="submit" class="btn btn-primary">Login</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from "vuex";
export default {
  name:'Login',
  components: {},
  data() {
    return {
      logo_kerinci: require('../../assets/images/logo_sppd.png'),
      form: {
        email: "",
        password: "",
      },
      showError: false
    };
  },
  methods: {
    ...mapActions(["handleLogin"]),
     async onSubmit() {
      const user = {
        'email': this.form.email,
        'password': this.form.password
      }
      
      try {
          await this.handleLogin(user);
          this.$router.push("/");
           
          this.showError = false
      } catch (error) {
        this.showError = true
      }
    },
  },
}
</script>